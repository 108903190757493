import '../Scss/main.scss';

window.addEventListener('load', () => {
    const modules = import.meta.glob([
        './Core/*.ts',
        './Content/*.ts',
        './Extensions/**/*.ts',
        './cookie.js',
    ]);

    for (const path in modules) {
        new Promise((_res, _rej) => modules[path]())
    }
});
