export default class XmlJobsList {
    constructor () {
        const nodes = document.querySelectorAll('.ce_jobs');

        if (!nodes) {
            return;
        }

        let width = 0;
        for (const node of nodes) {
            const subNode = node.querySelector('.orange .dl_link') as HTMLElement;
            (subNode.parentNode as HTMLElement).removeAttribute('style');
            const { clientWidth } = subNode;

            if (clientWidth > width) {
                width = clientWidth;
            }
        }

        for (const node of nodes) {
            const subNode = node.querySelector('.orange') as HTMLElement;
            subNode.style.maxWidth = `${width}px`;
        }
    }
}

new XmlJobsList();

window.addEventListener('resize', () => new XmlJobsList());
