(() => {
    const nodes = document.querySelectorAll('[data-bg]') as NodeListOf<HTMLElement>;

    if (!nodes.length) {
        return;
    }

    window.addEventListener('scroll', (e: Event) => {
        for (const node of nodes) {
            const bg = node.getAttribute('data-bg') as string;

            if (bg === null) {
                continue;
            }

            if (node.getBoundingClientRect().top < window.innerHeight) {
                node.style.opacity = '1';
                node.style.backgroundImage = bg;
                node.removeAttribute('data-bg');
            }
        }
    });
})();
