window.addEventListener('DOMContentLoaded', () => {
    const burger = <HTMLElement>document.querySelector('.burger');
    burger.onclick = (e: MouseEvent) => {
        e.preventDefault();

        burger.classList.toggle('active');
        (<HTMLElement>document.querySelector('.navigations')).classList.toggle('active');
    };

    for (const eventName of ['resize', 'scroll']) {
        window.dispatchEvent(new Event(eventName));
    }
});
