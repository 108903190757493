import { getCookie } from "../Utility/GetCookie";
import { inViewport } from "../Utility/InViewport";

let googleMapsScrolled = true;

(() => {
    // @ts-ignore
    window.activateGoogleMap = () => {
        // @ts-ignore
        window.gmegInitializeMap();
        const mapOverlay = document.querySelector('.ce_gmaps .overlay') as HTMLDivElement;
        mapOverlay?.remove();

        const hiddenMap = document.querySelector('.ce_gmaps .hidden_map') as HTMLDivElement;
        hiddenMap?.remove();

        const mapElement = document.querySelector('.ce_gmaps #map') as HTMLDivElement;
        if (mapElement) {
            mapElement.style.position = 'unset';
        }
    };

    // Google Maps activation based on scroll
    window.addEventListener('scroll', () => {
        const gMaps = document.querySelector('.ce_gmaps');
        if (!gMaps) return;

        if (gMaps && googleMapsScrolled && inViewport(gMaps)) {
            googleMapsScrolled = false;
            const cookieValue = getCookie(`cntb_set_${document.getElementById('cookienotice_box')!.getAttribute('data-configuration-uid')!}`);

            if (cookieValue.includes("googleMaps")) {
                // @ts-ignore
                window.activateGoogleMap();
            }

            gMaps.querySelectorAll('.overlay span').forEach(span => {
                span.addEventListener('click', () => {
                    if (cookieValue.includes("googleMaps")) {
                        // @ts-ignore
                        window.activateGoogleMap();
                    } else {
                        // @ts-ignore
                        window.cookieNoticeModul.initialise();
                        // @ts-ignore
                        window.cookieNoticeModul.open();
                        document.querySelector('[data-key="komfort"] .cookienotice-cookie-more')?.dispatchEvent(new Event('click'));
                        // @ts-ignore
                        document.querySelector('[data-key="komfort"] [for="cnts-3-googleMaps"]').parentNode.classList.add('cookiehighlight');
                    }
                });
            });
        }
    });
})();
