import tippy from 'tippy.js';
import 'tippy.js/dist/tippy.css';

(() => {
    const nodes = document.querySelectorAll<HTMLElement>('.ce_locationmap .marker-box');

    if (!nodes.length) {
        return;
    }

    for (const node of nodes) {
        const marker = <HTMLElement>node.querySelector('.marker');
        const tooltipContent = (<HTMLElement>node.querySelector('.tooltip')).outerHTML;

        tippy(marker, {
            content: tooltipContent,
            allowHTML: true,  // Allows HTML content inside the tooltip
            trigger: 'click',  // Tooltip will show on click
            hideOnClick: true,  // Hide the tooltip when clicking outside
            interactive: true,  // Allows interaction with the content of the tooltip
            placement: 'top',  // Automatically determines the best placement
            offset: [0, 60],
        });
    }
})();
