export default class FormValidation {
    constructor() {
        document.querySelectorAll('form').forEach(form => {
            form.addEventListener('submit', e => {
                let errors = false;

                form.querySelectorAll('input[required], textarea[required]').forEach(input => {
                    const value = (input as HTMLInputElement | HTMLTextAreaElement).value;
                    if (!value) {
                        input.classList.add('error');
                        errors = true;
                    } else {
                        input.classList.remove('error');
                    }

                    if (input instanceof HTMLInputElement && input.type === 'email' && !this.validateEmail(value)) {
                        input.classList.add('error');
                        errors = true;
                    }

                    if (input instanceof HTMLInputElement && input.type === 'checkbox' && !input.checked) {
                        input.classList.add('error');
                        errors = true;
                    }
                });

                if (errors) {
                    e.preventDefault();
                }
            });
        });
    }

    private validateEmail(email: string): boolean {
        const regex = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return regex.test(email);
    }
}
