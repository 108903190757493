import 'ol/ol.css';
import Map from 'ol/Map';
import View from 'ol/View';
import { fromLonLat } from 'ol/proj';
import TileLayer from 'ol/layer/Tile';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import OSM from 'ol/source/OSM';
import Feature from 'ol/Feature';
import Point from 'ol/geom/Point';
import Overlay from 'ol/Overlay';
import { Icon, Style } from 'ol/style';

// @ts-ignore
window.gmegInitializeMap = () => {
    console.trace();

    const myLocation = fromLonLat([
        // @ts-ignore
        parseFloat(window.MAPS_LNG),
        // @ts-ignore
        parseFloat(window.MAPS_LAT),
    ]);

    const overlaySource = new VectorSource({
        features: [new Feature({
            geometry: new Point(myLocation),
            name: 'OpenLayers'
        })]
    });

    const overlayLayer = new VectorLayer({
        source: overlaySource,
        // style: new Style({
        //     image: new Icon({
        //         src: 'path/to/your/icon.png',
        //         scale: 0.05
        //     })
        // })
    });

    const popupElement = document.createElement('div');
    popupElement.className = 'ol-popup';
    // @ts-ignore
    popupElement.innerHTML = window['MAPS_POPUP_HTML'];

    const popup = new Overlay({
        element: popupElement,
        positioning: 'bottom-center',
        stopEvent: false,
        offset: [0, -50],
        position: myLocation
    });

    const map = new Map({
        target: 'map',
        layers: [
            new TileLayer({
                source: new OSM()
            }),
            overlayLayer
        ],
        view: new View({
            center: myLocation,
            zoom: 15
        }),
        overlays: [popup]
    });

    popupElement.addEventListener('click', function () {
        popup.setPosition(undefined);
    });
};
